import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
  position: relative;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
    text-align: center;
  }
  h1 {
    background: #2b3541;
    padding: 30px;
  }
  .content {
    height: inherit;
    padding: 50px;
    background: #1f2832;
    @media (max-width: 800px) {
      padding: 80px 20px;
    }
  }
  p {
    width: 80%;
    padding: 30px 0;
    opacity: 0.8;
    font-family: nunitoReg, sans-serif;
    margin: 0 auto;
    max-width: 800px;
    @media (max-width: 800px) {
      width: 100%;
    }
    &:last-child {
      font-family: nunitoExtraBold, sans-serif;
      opacity: 0.3;
    }
  }
  a {
    color: white;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`

function Apply() {
  return (
    <Container>
      <h1 className="sectionHeader">How To Apply</h1>

      <div className="content">
        <p>
          We'd like to see how you work, we do this by looking at your code samples. We prefer looking at a Github account, because it gives us great
          insight in the quality of your code and your work process. We like to see bugs and how you fixed them, how you may have cooperated with
          others and overall project layout.
        </p>

        <p>
          Please send your resume and code samples to [<a href="mailto:jobs@splendo.com">jobs@splendo.com</a>] and we'll get back to you.
        </p>

        <p>Acquisition by recruitment agencies is not appreciated. Only engineers are invited to respond.</p>
      </div>
    </Container>
  )
}

export default Apply
